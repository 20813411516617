import React from 'react';
import {Link} from "react-router-dom";
import bongo from './img/dogs/bongo.JPG';
import dambo from './img/dogs/dambo.jpg';
import jimmy from './img/dogs/jimmy.jpeg';
import leo from './img/dogs/leo.jpeg';
import milan from './img/dogs/milan.jpg';
import rassel from './img/dogs/rassel.JPG';
import roxy from './img/dogs/roxy.jpg';
import hugo from './img/dogs/hugo.jpg';
import air from './img/dogs/air.jpeg';
import esmie from './img/dogs/esmie.jpeg';
import charly from './img/dogs/charly.jpg';
import bu from './img/dogs/bu.jpg';
import asti from './img/dogs/asti.jpg';
import bianca from './img/dogs/bianca.JPG';
import demi from './img/dogs/demi.JPG';

export class Dogs extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="dogs mb-10 col-sm-12">
                        <h3>Выбрать друга</h3>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/asti">
                            <img className="asti" src={asti} style={{width:'100%'}} alt="asti"/>
                            <h4 className="text-center" style={{position:'relative'}}>Асти</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/bianca">
                            <img className="bianca" src={bianca} style={{width:'100%'}} alt="bianca"/>
                            <h4 className="text-center" style={{position:'relative'}}>Бьянка</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/bongo">
                            <img className="bongo" src={bongo} style={{width:'100%'}} alt="bongo"/>
                            <h4 className="text-center" style={{position:'relative'}}>Бонго</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/bu">
                            <img className="bongo" src={bu} style={{width:'100%'}} alt="bu"/>
                            <h4 className="text-center" style={{position:'relative'}}>Бу</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/dambo">
                            <img className="dambo" src={dambo} style={{width:'100%'}} alt="dambo"/>
                            <h4 className="text-center">Дамбо</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/demi">
                            <img className="demi" src={demi} style={{width:'100%'}} alt="demi"/>
                            <h4 className="text-center">Деми</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/jimmy">
                            <img className="jimmy" src={jimmy} style={{width:'100%'}} alt="jimmy"/>
                            <h4 className="text-center">Джимми</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/leo">
                            <img className="leo" src={leo} style={{width:'100%'}} alt="leo"/>
                            <h4 className="text-center">Лео</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/milan">
                            <img className="milan" src={milan} style={{width:'100%'}} alt="milan"/>
                            <h4 className="text-center">Милан</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/rassel">
                            <img className="rassel" src={rassel} style={{width:'100%'}} alt="rassel"/>
                            <h4 className="text-center">Рассел</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/roxy">
                            <img className="roxy" src={roxy} style={{width:'100%'}} alt="roxy"/>
                            <h4 className="text-center">Рокси</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/hugo">
                            <img className="hugo" src={hugo} style={{width:'100%'}} alt="hugo"/>
                            <h4 className="text-center">Хьюго</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/charly">
                            <img className="charly" src={charly} style={{width:'100%'}} alt="charly"/>
                            <h4 className="text-center">Чарли</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/air">
                            <img className="air" src={air} style={{width:'100%'}} alt="air"/>
                            <h4 className="text-center">Эйр</h4>
                        </Link>
                    </div>
                    <div className="col-sm-3 pt-20">
                        <Link to="/esmie">
                            <img className="esmie" src={esmie} style={{width:'100%'}} alt="esmie"/>
                            <h4 className="text-center">Эсми</h4>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}