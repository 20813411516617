import React from "react";
import demi1 from "./img/dogs/demi/demi1.JPG";
import demi2 from "./img/dogs/demi/demi2.JPG";
import demi3 from "./img/dogs/demi/demi3.JPG";
import demi4 from "./img/dogs/demi/demi4.JPG";
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Demi extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="demi mb-30 col-sm-12">
                        <h3>Деми</h3>
                    </div>
                    <div className="demi mb-30 col-sm-3">
                        <img className="demi" src={demi1} style={{width:'100%'}} alt="demi1" />
                    </div>
                    <div className="demi mb-30 col-sm-3">
                        <img className="demi" src={demi2} style={{width:'100%'}} alt="demi2" />
                    </div>
                    <div className="demi mb-30 col-sm-3">
                        <img className="demi" src={demi3} style={{width:'100%'}} alt="demi3" />
                    </div>
                    <div className="demi mb-30 col-sm-3">
                        <img className="demi" src={demi4} style={{width:'100%'}} alt="demi4" />
                    </div>
                    <div className="demi mb-30 col-sm-12">
                        <h6>Возраст: 1,5 года.</h6>
                        <h6>Рост: 45 см.</h6>
                        <h6>Темперамент: меланхолик.</h6>
                        <h6>Психотип: экстраверт.</h6>
                        <p>
                            Бурая медведица Деми.
                        </p>
                        <p>
                            Такая плюшевая, такая мягкая, такая обнимательная, нежная и преданная красавица.
                        </p>
                        <p>
                            С ней хочется гулять по полям, по лесам, бросать фрисби, валяться в траве, ходить на пикник, а вечерами, сидя под пледом смотреть кино, деля на двоих бутерброд.
                        </p>
                        <p>
                            Вполне возможно, она тот самый пазл, которого вам так не хватает в жизни.
                        </p>
                        <p>
                            Забирайте любить!
                        </p>
                        <p>
                            Ладит с сородичами. Абсолютно не агрессивна. Приучена к поводку.
                        </p>
                        <p>
                            Деми здорова, привита, стерилизована, обработана от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.
                        </p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Деми, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}